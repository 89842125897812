import { Route, Routes } from "react-router-dom";
import {
  careersUrl,
  careersDynamicUrl,
  contactusUrl,
  csrinitiativesUrl,
  galleryUrl,
  galleryDynamicUrl,
  healthcareUrl,
  homeUrl,
  homeUrlDynamic,
  hospitalityUrl,
  hospitalityDynamicUrl,
  informationTechnologyUrl,
  infrastructureUrl,
  investmentUrl,
  newsCenterDetailsUrl,
  // newsCenterDynamicUrl,
  // newsCenterDetailsDynamicUrl,
  newsCenterUrl,
  ourCompanyDynamicUrl,
  ourCompanyUrl,
  privacyyUrl,
  renewableEnergyUrl,
  sportsUrl,
  teamManagementDynamicUrl,
  teamManagementUrl,
  termsUrl,
  contactusDynamicUrl,
  businessVentureDynamic,
  businessVentureDynamicUrl,
  mediaAndEntertainmentUrl,
  newsCenterDynamicUrl,
  newsCenterDetailsDynamicUrl,
} from "./components/helpers/constant-words";
import OurCompany from "./pages/our-company/OurCompany";
import OurCompanyDynamic from "./pages/our-company/OurCompanyDynamic";
import ManagementTeam from "./pages/management-team/ManagementTeam";
import ManagementTeamDynamic from "./pages/management-team/ManagementTeamDynamic";
import Gallery from "./pages/gallery/Gallery";
import Home from "./pages/Home/Home";
import Sports from "./pages/sports/Sports";
import Investment from "./pages/investment/Investment";
import ContactUs from "./pages/contact-us/ContactUs";
import Careers from "./pages/careers/Careers";
import CareersDynamic from "./pages/careers/CareersDynamic";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import CSRInitiatives from "./pages/csr-initiatives/CSRInitiatives";
import NewsCenter from "./pages/news-center/NewsCenter";
import NewsCenterExplore from "./pages/news-center-explore/NewsCenterExplore";
// import NewsCenterDynamic from "./pages/news-center/NewsCenterDynamic";
// import NewsCenterExploreDynamic from "./pages/news-center-explore/NewsCenterExploreDynamic";
import Hospitality from "./pages/Hospitality/Hospitality";
import HospitalityDynamic from "./pages/Hospitality/HospitalityDynamic";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/TermsAndConditions/PrivacyPolicy";
import HealthCare from "./pages/HealthCare/HealthCare";
import RenewableEnergy from "./pages/RenewableEnergy/RenewableEnergy";
import Infrastructure from "./pages/Infrastructure/Infrastructure";
import InformationTechnology from "./pages/InformationTechnology/InformationTechnology";
import HomeDynamic from "./pages/Home/HomeDynamic";
import GalleryDynamic from "./pages/gallery/GalleryDynamic";
import ContactUsDynamic from "./pages/contact-us/ContactUsDynamic";
import BusinessVentureDynamic from "./pages/BusinessVenturePages/BusinessVenturePage";
import MediaAndEntertainment from "./pages/MediaAndEntertainment/MediaAndEntertainment";
import NewsCenterDynamic from "./pages/news-center/NewsCenterDynamic";
import NewsCenterExploreDynamic from "./pages/news-center-explore/NewsCenterExploreDynamic";
import FooterDynamic from "./components/Footer/FooterDynamic";
import BusinessVentureDynamicV2 from "./pages/BusinessVenturePagesV2/BusinessVenturePagesV2";

const Routing = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path={ourCompanyUrl} element={<OurCompany />} />
        <Route path={homeUrl} element={<Home />} />
        <Route path={teamManagementUrl} element={<ManagementTeam />} />
        <Route path={galleryUrl} element={<Gallery />} />
        <Route path={sportsUrl} element={<Sports />} />
        <Route path={investmentUrl} element={<Investment />} />
        <Route path={contactusUrl} element={<ContactUs />} />
        <Route path={careersUrl} element={<Careers />} />
        <Route path={csrinitiativesUrl} element={<CSRInitiatives />} />
        <Route path={newsCenterUrl} element={<NewsCenter />} />
        <Route path={newsCenterDetailsUrl} element={<NewsCenterExplore />} />
        <Route path={hospitalityUrl} element={<Hospitality />} />
        <Route path={termsUrl} element={<TermsAndConditions />} />
        <Route path={privacyyUrl} element={<PrivacyPolicy />} />
        <Route path={healthcareUrl} element={<HealthCare />} />
        <Route path={renewableEnergyUrl} element={<RenewableEnergy />} />
        <Route path={infrastructureUrl} element={<Infrastructure />} />
        <Route
          path={mediaAndEntertainmentUrl}
          element={<MediaAndEntertainment />}
        />
        <Route
          path={informationTechnologyUrl}
          element={<InformationTechnology />}
        />

        {/* <Route path={ourCompanyDynamicUrl} element={<OurCompanyDynamic />} /> */}
        {/* <Route path={homeUrlDynamic} element={<HomeDynamic />} /> */}
        {/* <Route
          path={teamManagementDynamicUrl}
          element={<ManagementTeamDynamic />}
        /> */}
        {/* <Route path={galleryDynamicUrl} element={<GalleryDynamic />} /> */}
        {/* <Route path={contactusDynamicUrl} element={<ContactUsDynamic />} /> */}
        {/* <Route path={careersDynamicUrl} element={<CareersDynamic />} /> */}
        {/* <Route path={newsCenterDynamicUrl} element={<NewsCenterDynamic />} /> */}
        {/* <Route
          path={newsCenterDetailsDynamicUrl}
          element={<NewsCenterExploreDynamic />}
        /> */}
        {/* <Route
          path={newsCenterDetailsDynamicUrl}
          element={<NewsCenterExploreDynamic />}
        /> */}
        {/* <Route path={hospitalityDynamicUrl} element={<HospitalityDynamic />} /> */}
        {/* <Route
          path={businessVentureDynamicUrl}
          element={<BusinessVentureDynamic />}
        /> */}
        {/* <Route
          path={businessVentureDynamicUrl}
          element={<BusinessVentureDynamic />}
        /> */}
      </Routes>
      <Footer />
    </>
  );
};
export default Routing;

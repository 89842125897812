import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonBanner from "../../components/commonBanner/CommonBanner";
import SectionContent from "../../components/SectionContent/SectionContent";
import "./newscenter.scss";
import { newsCentreBanner, newsCentreBannerMb } from "../../images";
import { useWindowSize } from "react-use";
import http from "../../helpers/http";
import config from "../../config/config";
import FsLightbox from "fslightbox-react";
import NewsCardDynamic from "../../components/NewsCard/NewsCardDynamic";
import moment from "moment";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import SectionContentDynamic from "../../components/SectionContent/SectionContentDynamic";
import { newsCenterDynamicUrl } from "../../components/helpers/constant-words";

const NewsCenterDynamic = () => {
  const { width } = useWindowSize();
  const base_url = config.api_url;
  const image_url = config.image_url;
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [sectionBanner, setSectionBanner] = useState([]);
  const [newsData, setNewsData] = useState({});
  const [totalCounts, setTotalCounts] = useState({});
  const limit = 3;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");
  const [metaInfo, setMetaInfo] = useState({});

  const location = useLocation();

  const fetchCategories = async () => {
    const [newsData, sectionBanners] = await Promise.all([
      http.get(`${base_url}/newscentre/categories`),
      http.get(`${base_url}/section-banners`),
    ]);

    if (newsData?.data && sectionBanners?.data) {
      setCategories(newsData.data);
      setSectionBanner(
        sectionBanners.data.find((it) => it.section === "news-centre")
      );
    }
  };

  const fetchInitialNews = async () => {
    const { data } = await http.get(`${base_url}/newscentre/initial`);
    if (data) {
      const newsDataByCategory = {};
      const totalCountsByCategory = {};

      Object.keys(data).forEach((categoryName) => {
        newsDataByCategory[categoryName] = data[categoryName].news.map(
          (item) => ({
            id: item._id,
            slug: item.slug,
            img: `${image_url}${item.image.url}`,
            alt: item?.alt_tag,
            title: item.title,
            date: moment(item.createdAt).format("MMMM D, YYYY"),
            desc: item.description,
            cta: item.cta,
            content_display: item.content_display,
            link_type: item?.link_type,
          })
        );
        totalCountsByCategory[categoryName] = data[categoryName].totalCount;
      });

      setNewsData(newsDataByCategory);
      setTotalCounts(totalCountsByCategory);
      setLoading(false);
    }
  };

  const fetchMetaInfo = async () => {
    const { data } = await http.get(`${base_url}/page-meta/news_centre`);

    if (data) {
      setMetaInfo(data);
    }
  };

  const handleLoadMore = async (category) => {
    const currentCount = newsData[category].length;

    const { data } = await http.get(
      `${base_url}/newscentre?category=${category}&skip=${currentCount}&limit=${limit}`
    );
    if (data) {
      setNewsData((prevData) => ({
        ...prevData,
        [category]: [
          ...prevData[category],
          ...data.map((item) => ({
            id: item._id,
            slug: item.slug,
            img: `${image_url}${item?.image?.url}`,
            alt: item?.alt_tag,
            title: item.title,
            date: moment(item.createdAt).format("MMMM D, YYYY"),
            desc: item?.description,
            cta: item?.cta,
            content_display: item.content_display,
            link_type: item?.link_type,
          })),
        ],
      }));
    }
  };

  const handleCardClick = (id, content_display, link_type, link) => {
    let foundRecord = null;
    for (const category in newsData) {
      const record = newsData[category].find((item) => item.id === id);
      if (record) {
        foundRecord = record;
        break; // Stop searching once we find the record
      }
    }
    if (foundRecord) {
      if (foundRecord.link_type === "inner-page-link") {
        navigate(`/news-center-dynamic/${foundRecord.slug}`);
      } else if (foundRecord.link_type === "video") {
        handleWatchClick(foundRecord.cta);
      } else if (foundRecord.link_type === "external-link") {
        handleExternalLink(foundRecord.cta);
      }
    }
  };

  const handleExternalLink = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const handleWatchClick = (videoLink) => {
    console.log(videoLink);

    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  useEffect(() => {
    fetchCategories();
    fetchMetaInfo();
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      fetchInitialNews();
    }
  }, [categories]);

  useEffect(() => {
    const category = location.state?.category;
    console.log(category);
    console.log(categories);
    // if (hash) {
    //   const targetId = hash.substring(1);
    //   const targetSection = document.getElementById(targetId);
    //   if (targetSection) {
    //     setTimeout(() => {
    //       const offset = 120; // Gap from viewport top
    //       const targetPosition =
    //         targetSection.getBoundingClientRect().top + window.scrollY - offset;
    //       window.scrollTo({ top: targetPosition, behavior: "smooth" });
    //       // targetSection.scrollIntoView({ behavior: "smooth" });
    //     }, 0);
    //     navigate(newsCenterDynamicUrl, { replace: true });
    //   }
    // } else {
    //   window.scrollTo(0, 0);
    // }
  }, []);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <MetaDecorator
        metaTitle={metaInfo?.meta_title}
        metaDesc={metaInfo?.meta_description}
        canonicalLink={window.location}
      />
      <CommonBanner
        title={"News Center"}
        banner={
          width > 767
            ? `${image_url}${sectionBanner?.desktop_banner?.url}`
            : `${image_url}${sectionBanner?.mobile_banner?.url}`
        }
        linkText1={"News Centre"}
      />

      <div className="news-center">
        {categories.map((category) => (
          <div key={category._id} id={category._id}>
            <SectionContentDynamic
              leftHeading={category.name.toUpperCase()}
              desc={category.description}
            />

            <div className="news-center-cards-press">
              <NewsCardDynamic
                dataList={newsData[category.name] || []}
                onCardClick={handleCardClick}
                layout_type={category.layout_type}
              />
              {newsData[category.name]?.length < totalCounts[category.name] && (
                <div className="btn-container">
                  <button
                    className="load-more"
                    onClick={() => handleLoadMore(category.name)}
                  >
                    LOAD MORE
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <section className="news_ligibox_wrapper">
        <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
      </section>
    </>
  );
};

export default NewsCenterDynamic;

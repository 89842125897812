import { images } from "../images";

export const galleryImage = [
  // infrastructure images
  {
    src: images.infra05.image,
    alt: images.infra05.alt,
    category: "infrastructure",
  },
  {
    src: images.infra06.image,
    alt: images.infra06.alt,
    category: "infrastructure",
  },
  {
    src: images.infra07.image,
    alt: images.infra07.alt,
    category: "infrastructure",
  },
  {
    src: images.infra08.image,
    alt: images.infra08.alt,
    category: "infrastructure",
  },
  {
    src: images.infra09.image,
    alt: images.infra09.alt,
    category: "infrastructure",
  },
  {
    src: images.infra10.image,
    alt: images.infra10.alt,
    category: "infrastructure",
  },
  {
    src: images.infra11.image,
    alt: images.infra11.alt,
    category: "infrastructure",
  },
  {
    src: images.infra12.image,
    alt: images.infra12.alt,
    category: "infrastructure",
  },
  {
    src: images.infra01.image,
    alt: images.infra01.alt,
    category: "infrastructure",
  },
  {
    src: images.infra02.image,
    alt: images.infra02.alt,
    category: "infrastructure",
  },
  {
    src: images.infra03.image,
    alt: images.infra03.alt,
    category: "infrastructure",
  },
  {
    src: images.infra04.image,
    alt: images.infra04.alt,
    category: "infrastructure",
  },

  // hospitality images
  {
    src: images.hospitality28.image,
    alt: images.hospitality28.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality19.image,
    alt: images.hospitality19.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality20.image,
    alt: images.hospitality20.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality21.image,
    alt: images.hospitality21.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality22.image,
    alt: images.hospitality22.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality23.image,
    alt: images.hospitality23.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality24.image,
    alt: images.hospitality24.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality25.image,
    alt: images.hospitality25.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality26.image,
    alt: images.hospitality26.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality27.image,
    alt: images.hospitality27.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality01.image,
    alt: images.hospitality01.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality02.image,
    alt: images.hospitality02.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality03.image,
    alt: images.hospitality03.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality04.image,
    alt: images.hospitality04.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality05.image,
    alt: images.hospitality05.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality06.image,
    alt: images.hospitality06.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality07.image,
    alt: images.hospitality07.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality08.image,
    alt: images.hospitality08.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality09.image,
    alt: images.hospitality09.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality10.image,
    alt: images.hospitality10.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality11.image,
    alt: images.hospitality11.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality12.image,
    alt: images.hospitality12.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality13.image,
    alt: images.hospitality13.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality14.image,
    alt: images.hospitality14.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality15.image,
    alt: images.hospitality15.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality16.image,
    alt: images.hospitality16.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality17.image,
    alt: images.hospitality17.alt,
    category: "hospitality",
  },
  {
    src: images.hospitality18.image,
    alt: images.hospitality18.alt,
    category: "hospitality",
  },

  // healthcare images
  {
    src: images.healthcare01.image,
    alt: images.healthcare01.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare02.image,
    alt: images.healthcare02.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare03.image,
    alt: images.healthcare03.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare04.image,
    alt: images.healthcare04.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare05.image,
    alt: images.healthcare05.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare06.image,
    alt: images.healthcare06.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare07.image,
    alt: images.healthcare07.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare08.image,
    alt: images.healthcare08.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare09.image,
    alt: images.healthcare09.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare10.image,
    alt: images.healthcare10.alt,
    category: "healthcare",
  },
  {
    src: images.healthcare11.image,
    alt: images.healthcare11.alt,
    category: "healthcare",
  },

  // renewableEnergy images
  {
    src: images.renergy01.image,
    alt: images.renergy01.alt,
    category: "renewableEnergy",
  },
  {
    src: images.renergy02.image,
    alt: images.renergy02.alt,
    category: "renewableEnergy",
  },
  {
    src: images.renergy03.image,
    alt: images.renergy03.alt,
    category: "renewableEnergy",
  },
  {
    src: images.renergy04.image,
    alt: images.renergy04.alt,
    category: "renewableEnergy",
  },
  {
    src: images.renergy05.image,
    alt: images.renergy05.alt,
    category: "renewableEnergy",
  },
  {
    src: images.renergy06.image,
    alt: images.renergy06.alt,
    category: "renewableEnergy",
  },

  // sports images

  {
    src: images.sports25.image,
    alt: images.sports25.alt,
    category: "sports",
  },
  {
    src: images.sports21.image,
    alt: images.sports21.alt,
    category: "sports",
  },
  {
    src: images.sports22.image,
    alt: images.sports22.alt,
    category: "sports",
  },
  {
    src: images.sports23.image,
    alt: images.sports23.alt,
    category: "sports",
  },
  {
    src: images.sports24.image,
    alt: images.sports24.alt,
    category: "sports",
  },
 
  {
    src: images.sports26.image,
    alt: images.sports26.alt,
    category: "sports",
  },
  {
    src: images.sports27.image,
    alt: images.sports27.alt,
    category: "sports",
  },
  {
    src: images.sports28.image,
    alt: images.sports28.alt,
    category: "sports",
  },
  {
    src: images.sports29.image,
    alt: images.sports29.alt,
    category: "sports",
  },
  {
    src: images.sports30.image,
    alt: images.sports30.alt,
    category: "sports",
  },
  {
    src: images.sports31.image,
    alt: images.sports31.alt,
    category: "sports",
  },
  {
    src: images.sports32.image,
    alt: images.sports32.alt,
    category: "sports",
  },
  {
    src: images.sports33.image,
    alt: images.sports33.alt,
    category: "sports",
  },
  {
    src: images.sports34.image,
    alt: images.sports34.alt,
    category: "sports",
  },
  {
    src: images.sports35.image,
    alt: images.sports35.alt,
    category: "sports",
  },
  {
    src: images.sports36.image,
    alt: images.sports36.alt,
    category: "sports",
  },
  {
    src: images.sports37.image,
    alt: images.sports37.alt,
    category: "sports",
  },
  {
    src: images.sports38.image,
    alt: images.sports38.alt,
    category: "sports",
  },
  {
    src: images.sports39.image,
    alt: images.sports39.alt,
    category: "sports",
  },
  {
    src: images.sports40.image,
    alt: images.sports40.alt,
    category: "sports",
  },
  {
    src: images.sports01.image,
    alt: images.sports01.alt,
    category: "sports",
  },
  {
    src: images.sports02.image,
    alt: images.sports02.alt,
    category: "sports",
  },
  {
    src: images.sports03.image,
    alt: images.sports03.alt,
    category: "sports",
  },
  {
    src: images.sports04.image,
    alt: images.sports04.alt,
    category: "sports",
  },
  {
    src: images.sports05.image,
    alt: images.sports05.alt,
    category: "sports",
  },
  {
    src: images.sports06.image,
    alt: images.sports06.alt,
    category: "sports",
  },
  {
    src: images.sports07.image,
    alt: images.sports07.alt,
    category: "sports",
  },
  {
    src: images.sports08.image,
    alt: images.sports08.alt,
    category: "sports",
  },
  {
    src: images.sports09.image,
    alt: images.sports09.alt,
    category: "sports",
  },
  {
    src: images.sports10.image,
    alt: images.sports10.alt,
    category: "sports",
  },
  {
    src: images.sports11.image,
    alt: images.sports11.alt,
    category: "sports",
  },
  {
    src: images.sports12.image,
    alt: images.sports12.alt,
    category: "sports",
  },
  {
    src: images.sports13.image,
    alt: images.sports13.alt,
    category: "sports",
  },
  {
    src: images.sports14.image,
    alt: images.sports14.alt,
    category: "sports",
  },
 
  {
    src: images.sports15.image,
    alt: images.sports15.alt,
    category: "sports",
  },
  {
    src: images.sports16.image,
    alt: images.sports16.alt,
    category: "sports",
  },
  {
    src: images.sports17.image,
    alt: images.sports17.alt,
    category: "sports",
  },
  {
    src: images.sports18.image,
    alt: images.sports18.alt,
    category: "sports",
  },
  {
    src: images.sports19.image,
    alt: images.sports19.alt,
    category: "sports",
  },

  // Media & Entertainment images
  {
    src: images.media01.image,
    alt: images.media01.alt,
    category: "media",
  },
  {
    src: images.media02.image,
    alt: images.media02.alt,
    category: "media",
  },
  {
    src: images.media03.image,
    alt: images.media03.alt,
    category: "media",
  },
  {
    src: images.media04.image,
    alt: images.media04.alt,
    category: "media",
  },
  {
    src: images.media05.image,
    alt: images.media05.alt,
    category: "media",
  },


  // IT images
  {
    src: images.it01.image,
    alt: images.it01.alt,
    category: "it",
  },
  {
    src: images.it02.image,
    alt: images.it02.alt,
    category: "it",
  },
  {
    src: images.it03.image,
    alt: images.it03.alt,
    category: "it",
  },

  // Add more images as needed
];
